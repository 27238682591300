import Repository, { baseUrl } from "./Repository";

export function addState(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(`${baseUrl}/add_state`, payload);

      return resolve(response.data);
    } catch (error) {}
  });
}

export function getState(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(`${baseUrl}/get_state`, payload);

      return resolve(response.data);
    } catch (error) {}
  });
}
export function updateState(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/update_state`,
        payload
      );

      return resolve(response.data);
    } catch (error) {}
  });
}

export function addCountry(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      console.log("hoo", baseUrl);
      const response = await Repository.post(`${baseUrl}/add_country`, payload);

      return resolve(response.data);
    } catch (error) {}
  });
}

export function getCountry(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(`${baseUrl}/get_country`, payload);

      return resolve(response.data);
    } catch (error) {}
  });
}
export function updateCountry(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/update_country`,
        payload
      );

      return resolve(response.data);
    } catch (error) {}
  });
}
