import Repository, { baseUrl } from "./Repository";

export function addCourseLevel(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/add_course_level`,
        payload
      );

      return resolve(response.data);
    } catch (error) {}
  });
}
export function getCourseLevel(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/get_course_level`,
        payload
      );

      return resolve(response.data);
    } catch (error) {}
  });
}
export function updateCourseLevel(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/update_course_level`,
        payload
      );

      return resolve(response.data);
    } catch (error) {}
  });
}
export function addCourseType(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/add_course_type`,
        payload
      );

      return resolve(response.data);
    } catch (error) {}
  });
}
export function getCourseType(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/get_course_type`,
        payload
      );

      return resolve(response.data);
    } catch (error) {}
  });
}
export function updateCourseType(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/update_course_type`,
        payload
      );

      return resolve(response.data);
    } catch (error) {}
  });
}
export function addCourseDuration(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/add_course_duration`,
        payload
      );

      return resolve(response.data);
    } catch (error) {}
  });
}
export function getCourseDuration(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/get_course_duration`,
        payload
      );

      return resolve(response.data);
    } catch (error) {}
  });
}
export function updateCourseDuration(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/update_course_duration`,
        payload
      );

      return resolve(response.data);
    } catch (error) {}
  });
}
export function addCourseExperience(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/add_course_experience`,
        payload
      );

      return resolve(response.data);
    } catch (error) {}
  });
}
export function getCourseExperience(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/get_course_experience`,
        payload
      );

      return resolve(response.data);
    } catch (error) {}
  });
}
export function updateCourseExperience(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/update_course_experience`,
        payload
      );

      return resolve(response.data);
    } catch (error) {}
  });
}
