import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { UncontrolledAlert } from "reactstrap";
import {
  getWorkingPolicy,
  updateWorkingPolicy,
} from "../../repositories/settingRepository";
import { useHistory } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import { set } from "lodash";

const WorkingPolicy = (props) => {
  const [workingPolicy, setWorkingPolicy] = useState("");
  const [workingId, setWorkingId] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const history = useHistory();

  useEffect(async () => {
    GetWorkingPolicy();
  }, [props.success]);

  const GetWorkingPolicy = async () => {
    let res = await getWorkingPolicy();
    setWorkingPolicy(res.data.working_policy);
    setWorkingId(res.data._id);
  };

  async function handleValidSubmit(event, values) {
    window.scrollTo(0, 0);
    let res = await updateWorkingPolicy({
      working_policy: workingPolicy,
      working_id: workingId,
    });
    if (res.status == 1) {
      setSuccess(res.message);

      setTimeout(() => {
        setSuccess(null);
        history.push("/working-policy");
      }, 3000);
    } else {
      setError(res.message);
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <Col lg="12">
            {error ? (
              <UncontrolledAlert color="danger" isOpen={true} dismissible>
                {error}
              </UncontrolledAlert>
            ) : null}
            {success ? (
              <UncontrolledAlert color="success" isOpen={true} dismissible>
                {success}
              </UncontrolledAlert>
            ) : null}
          </Col>
        </Row>

        <h4 className="card-title mb-4">Working Policy</h4>

        <Card>
          <CardBody>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              <div className="form-group">
                <CKEditor
                  editor={ClassicEditor}
                  data={workingPolicy}
                  config={{ placeholder: "enter Working policy ..." }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setWorkingPolicy(data);
                  }}
                />
              </div>

              <div className="text-center mt-4">
                <Button type="submit" color="primary">
                  Update
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default WorkingPolicy;
