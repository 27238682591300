import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { UncontrolledAlert } from "reactstrap";
import { getUniversityProfile } from "../../repositories/adminRepository";
import { useHistory, Link } from "react-router-dom";
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import { set } from "lodash";
import moment from "moment";
import { imageUrl } from "../../repositories/Repository";

const UniversityInsight = (props) => {
  const [user, setUser] = useState({});
  const [adminId, setAdminId] = useState("");
  const [sizeId, setSizeId] = useState("");
  const [button, setButton] = useState("Submit");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const history = useHistory();
  const [type, setType] = useState("");

  useEffect(async () => {
    let typ = window.location.pathname.split("/").pop();
    if (typ !== "new") {
      let res = await getUniversityProfile({ user_id: typ, for_country: true });
      if (res.status == 1) {
        setUser(res.data[0]);
      }
    }
    setType(typ);
  }, [props.success]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <Col lg="12">
            {error ? (
              <UncontrolledAlert color="danger" isOpen={true} dismissible>
                {error}
              </UncontrolledAlert>
            ) : null}
            {success ? (
              <UncontrolledAlert color="success" isOpen={true} dismissible>
                {success}
              </UncontrolledAlert>
            ) : null}
          </Col>
        </Row>

        <h1 className="card-title mb-4 ms-5 fs-3">University Details </h1>

        <Card className="col-sm-11 ms-auto me-auto">
          <CardBody>
            <Row className="p-2">
              <Col lg="12">
                <h4>University Register Details </h4>
                <Link to={`/view-courses/${type}`}>
                  <Button style={{ float: "right" }}>See Courses</Button>
                </Link>
              </Col>
            </Row>
            <div style={{ display: "flex" }}>
              {/* <img
                style={{ alignItems: "flex-end" }}
                src={`${imageUrl}${user?.profile_picture}`}
                height={60}
                width={60}
                className="mb-3"
              /> */}
            </div>

            <AvForm
              className="form-horizontal"
              // onValidSubmit={(e, v) => {
              //   handleValidSubmit(e, v);
              // }}
            >
              <div className="form-group">
                <AvField
                  name="name"
                  label="University Name"
                  value={user?.name}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="email"
                  label="Email"
                  value={user?.email}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="mobile"
                  label="Mobile"
                  value={user?.mobile}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="website"
                  label="Official Website"
                  value={user?.official_website}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="country"
                  label="Country"
                  value={user?.country_detail?.name}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="state"
                  label="State"
                  value={user?.state_detail?.name}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
              </div>
            </AvForm>
          </CardBody>
        </Card>

        <Card className="col-sm-11 ms-auto me-auto">
          <CardBody>
            <h4>Other Details</h4>
            <br />
            <AvForm
              className="form-horizontal"
              // onValidSubmit={(e, v) => {
              //   handleValidSubmit(e, v);
              // }}
            >
              <div className="form-group">
                {/* <AvField
                  name="about"
                  label="About University"
                  // value={user.university_details?.about}
                  className="form-control"
                  type="textarea"
                  rows="6"
                  disabled
                  dangerouslySetInnerHTML={{
                    __html: user.university_details?.about,
                  }}
                /> */}
                <label>About University</label>
                <p
                  style={{ height: "100px", overflowY: "scroll" }}
                  type="text"
                  dangerouslySetInnerHTML={{
                    __html: user.university_details?.about,
                  }}
                />
                <br />
                <AvField
                  name="year"
                  label="Established Year"
                  value={user.university_details?.established_year}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="type"
                  label="University Type"
                  value={user.university_details?.university_type}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="rating"
                  label="Rating"
                  value={user.university_details?.rating}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="ranking"
                  label="World Ranking"
                  value={user.university_details?.world_rank}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="scolarship"
                  label="Scolarship"
                  value={
                    user.university_details?.scholarship
                      ? "Allowed"
                      : "Not Allowed"
                  }
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="accomodation"
                  label="Accomodation"
                  value={
                    user.university_details?.accomodation
                      ? "Allowed"
                      : "Not Allowed"
                  }
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="part"
                  label="Part Time Work"
                  value={
                    user.university_details?.part_time_work
                      ? "Allowed"
                      : "Not Allowed"
                  }
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
              </div>
            </AvForm>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default UniversityInsight;
