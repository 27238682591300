import React from "react";
import { useState, useEffect } from "react";
import { Table, Button, Row, Col, Card, CardBody } from "reactstrap";
import {
  getUserList,
  changeUserStatus,
} from "../../repositories/adminRepository";
import ReactPaginate from "react-paginate";
import { UncontrolledAlert } from "reactstrap";
import { useHistory } from "react-router-dom";

import { Link } from "react-router-dom";

export default function ApprovalRequest() {
  const [userList, setUserList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [currentpage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const history = useHistory();

  let pagesize = 10;
  useEffect(() => {
    UserList();
    setRefresh(false);
  }, [currentpage, refresh]);

  const verifyUser = async (id) => {
    let res = await changeUserStatus({ is_verify: true, user_id: id });
    if (res.status == 1) {
      setRefresh(true);

      setSuccess(res.message);
      setTimeout(() => {
        setSuccess(null);
      }, 5000);
    } else {
      setError(res.message);
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  };

  const UserList = async () => {
    let res = await getUserList({
      page: currentpage,
      pagesize: pagesize,
      for_approval: true,
    });

    setUserList(res.data);
    setDataCount(res.count);
    let pageCount1 = Math.ceil(res.count / pagesize);
    setPageCount(pageCount1);
  };

  const onPageSubmit = (value) => {
    setCurrentPage(value.selected + 1);
    console.log("value", value.selected + 1);
  };

  const deleteUser = async (value) => {
    let res = await changeUserStatus({ user_id: value });
    if (res.status == 1) {
      setRefresh(true);

      setSuccess(res.message);
      setTimeout(() => {
        setSuccess(null);
      }, 5000);
    } else {
      setError(res.message);
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
    console.log(res);
  };
  return (
    <>
      <div className="page-content">
        <Row>
          <Col lg="12">
            {error ? (
              <UncontrolledAlert color="danger" isOpen={true} dismissible>
                {error}
              </UncontrolledAlert>
            ) : null}
            {success ? (
              <UncontrolledAlert color="success" isOpen={true} dismissible>
                {success}
              </UncontrolledAlert>
            ) : null}
          </Col>
        </Row>
        <div>
          <h5>Universities List</h5>
        </div>
        <Card>
          <CardBody>
            <div className="table-responsive">
              <Table hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>University Name</th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th>Website</th>

                    <th>Country</th>
                    <th>State</th>
                    <th>Status</th>

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {userList?.map((ele, index) => {
                    //   console.log("ele", ele)
                    return (
                      <tr key={ele._id}>
                        <th scope="row">{index + 1}</th>
                        <td>{ele?.name}</td>
                        <td>{ele?.email}</td>
                        <td>{ele?.mobile}</td>
                        <td>{ele?.official_website}</td>
                        <td>{ele?.country?.name}</td>
                        <td>{ele?.state?.name}</td>
                        <td>
                          {ele.is_approved ? (
                            <Button
                              style={{
                                backgroundColor: "#043927",
                                borderRadius: "20px",
                              }}
                              disabled
                            >
                              {"Approved"}
                            </Button>
                          ) : (
                            <Button
                              style={{
                                backgroundColor: "grey",
                                borderRadius: "20px",
                              }}
                              disabled
                            >
                              {"Pending"}
                            </Button>
                          )}
                        </td>
                        <td>
                          <div>
                            {/* <Link to={`/add-faq/${ele._id}`} className="pe-3">
                              <i title="insight" class="fas fa-eye fs-5"></i>
                            </Link> */}

                            {!ele.is_approved && (
                              <Link>
                                <i
                                  title="verify"
                                  className="  fas fa-check-circle fs-5"
                                  onClick={() => verifyUser(ele._id)}
                                />
                              </Link>
                            )}

                            <Link>
                              <i
                                className=" fas fa-trash-alt ms-3 fs-5"
                                size={20}
                                onClick={(e) => {
                                  deleteUser(ele._id);
                                }}
                              />
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <div style={{ display: "flex" }}>
              <ReactPaginate
                breakLabel="..."
                nextLabel="next >"
                onPageChange={onPageSubmit}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="< previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}

                //   renderOnZeroPageCount={null}
              />
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
}
