import React, { Component } from "react";

import { Row, Col, Input, Button, Alert, Container, Label } from "reactstrap";
import { UncontrolledAlert } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// actions
import { checkLogin, apiError } from "../../store/actions";

// import images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import { loginAdmin } from "../../repositories/adminRepository";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { username: "", password: "", success: null, error: null };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(event, values) {
    let res = await loginAdmin({
      email: values.username,
      password: values.password,
    });
    console.log("login res", res);

    if (res.status === 1) {
      this.setState({ success: res.message });

      localStorage.setItem("authUser", res.access_token);
      localStorage.setItem("username", res.data.email);
      setTimeout(() => {
        this.setState({ success: null });
        this.props.history.push("/dashboard");
      }, 3000);
    } else {
      this.setState({ error: res.message });
      setTimeout(() => {
        this.setState({ error: null });
      }, 5000);
    }
  }

  componentDidMount() {
    this.props.apiError("");
    document.body.classList.add("auth-body-bg");
  }

  componentWillUnmount() {
    document.body.classList.remove("auth-body-bg");
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <Container fluid className="p-0">
            <Row className="g-0">
              <Col lg={4}>
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100">
                    <Row className="justify-content-center">
                      <Col lg={9}>
                        <div>
                          <div className="text-center">
                            <Row>
                              <Col lg="12">
                                {this.state.error ? (
                                  <UncontrolledAlert
                                    color="danger"
                                    isOpen={true}
                                    dismissible
                                  >
                                    {this.state.error}
                                  </UncontrolledAlert>
                                ) : null}
                                {this.state.success ? (
                                  <UncontrolledAlert
                                    color="success"
                                    isOpen={true}
                                    dismissible
                                  >
                                    {this.state.success}
                                  </UncontrolledAlert>
                                ) : null}
                              </Col>
                            </Row>
                            <div>
                              {console.log(this.state)}
                              <Link to="/" class="">
                                <img
                                  src={logodark}
                                  alt=""
                                  height="20"
                                  class="auth-logo logo-dark mx-auto"
                                />
                                <img
                                  src={logolight}
                                  alt=""
                                  height="20"
                                  class="auth-logo logo-light mx-auto"
                                />
                              </Link>
                            </div>

                            <h4 className="font-size-18 mt-4">
                              Welcome Back !
                            </h4>
                            <p className="text-muted">
                              Sign in to continue to Scholarship.
                            </p>
                          </div>

                          {this.props.loginError && this.props.loginError ? (
                            <Alert color="danger">
                              {this.props.loginError}
                            </Alert>
                          ) : null}

                          <div className="p-2 mt-5">
                            <AvForm
                              className="form-horizontal"
                              onValidSubmit={this.handleSubmit}
                            >
                              <div className="auth-form-group-custom mb-4">
                                <i className="ri-user-2-line auti-custom-input-icon"></i>
                                <Label htmlFor="username">Email</Label>
                                <AvField
                                  name="username"
                                  value={this.state.username}
                                  type="text"
                                  className="form-control"
                                  id="username"
                                  validate={{ email: true, required: true }}
                                  placeholder="Enter Email"
                                />
                              </div>

                              <div className="auth-form-group-custom mb-4">
                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                <Label htmlFor="userpassword">Password</Label>
                                <AvField
                                  name="password"
                                  value={this.state.password}
                                  type="password"
                                  className="form-control"
                                  id="userpassword"
                                  placeholder="Enter password"
                                />
                              </div>

                              <div className="mt-4 text-center">
                                <Button
                                  color="primary"
                                  className="w-md waves-effect waves-light"
                                  type="submit"
                                >
                                  Log In
                                </Button>
                              </div>
                            </AvForm>
                          </div>

                          <div className="mt-5 text-center">
                            <p>
                              © 2022 Scholarship. powered{" "}
                              <i className="mdi mdi-heart text-danger"></i> by
                              Revolution Software Services
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col lg={8}>
                <div className="authentication-bg">
                  <div className="bg-overlay"></div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { loginError } = state.Login;
  return { loginError };
};

export default withRouter(
  connect(mapStatetoProps, { checkLogin, apiError })(Login)
);
