import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { UncontrolledAlert } from "reactstrap";
import { getUserProfile } from "../../repositories/adminRepository";
import { useHistory } from "react-router-dom";
import { saveAs } from "file-saver";
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import { set } from "lodash";
import moment from "moment";
import { imageUrl } from "../../repositories/Repository";

const UserInsight = (props) => {
  const [user, setUser] = useState({});
  const [adminId, setAdminId] = useState("");
  const [sizeId, setSizeId] = useState("");
  const [button, setButton] = useState("Submit");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const history = useHistory();
  const [type, setType] = useState("");

  useEffect(async () => {
    let typ = window.location.pathname.split("/").pop();
    if (typ !== "new") {
      let res = await getUserProfile({ user_id: typ });
      if (res.status == 1) {
        setUser(res.data);
      }
    }
    setType(typ);
  }, [props.success]);

  const viewDoc = (e, url) => {
    window.open(`${imageUrl}/${url}`, "_blank");
  };

  const downloadDoc = (e, url, degree) => {
    let ext = url.split(".")[url.split(".").length - 1];
    console.log("this is extension", ext);
    saveAs(
      `${imageUrl}/${url}`,
      user.name ? user.name + "_" + degree : `marksheet.${ext}`
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <Col lg="12">
            {error ? (
              <UncontrolledAlert color="danger" isOpen={true} dismissible>
                {error}
              </UncontrolledAlert>
            ) : null}
            {success ? (
              <UncontrolledAlert color="success" isOpen={true} dismissible>
                {success}
              </UncontrolledAlert>
            ) : null}
          </Col>
        </Row>

        <h1 className="card-title mb-4 ms-5 fs-3">Student Details </h1>

        <Card className="col-sm-11 ms-auto me-auto">
          <CardBody>
            <div style={{ display: "flex" }}>
              <h4>Personal Details</h4>
              {/* <img
                style={{ alignItems: "flex-end" }}
                src={`${imageUrl}${user?.profile_picture}`}
                height={60}
                width={60}
                className="mb-3"
              /> */}
            </div>
            <br />

            <AvForm
              className="form-horizontal"
              // onValidSubmit={(e, v) => {
              //   handleValidSubmit(e, v);
              // }}
            >
              <div className="form-group">
                <AvField
                  name="name"
                  label="Student Name"
                  value={user?.name}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="email"
                  label="Email"
                  value={user?.email}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="mobile"
                  label="Mobile"
                  value={user?.mobile}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="father"
                  label="Father Name"
                  value={user?.father_name}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="language"
                  label="First Language"
                  value={user?.first_language}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="dob"
                  label="D.O.B"
                  value={moment(user?.dob).format("MMM-DD-YYYY")}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="gender"
                  label="Gender"
                  value={user?.gender}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="citizenship"
                  label="Country of Citizenship"
                  value={user?.citizenship_country}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="passport_number"
                  label="Passport Number"
                  value={user?.passport_number}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="marital_status"
                  label="Marital Status"
                  value={user?.marital_status}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />

                <AvField
                  name="country"
                  label="Country"
                  value={user?.country?.name}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="state"
                  label="State"
                  value={user?.state?.name}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="address"
                  label="Address"
                  value={user?.address}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="zipcode"
                  label="Postal/Zip Code"
                  value={user?.pincode}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />

                <AvField
                  name="alternate_mobile"
                  label="Alternate Phone"
                  value={user?.alternative_mobile}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
              </div>
            </AvForm>
          </CardBody>
        </Card>

        <Card className="col-sm-11 ms-auto me-auto">
          <CardBody>
            <h3>Educational Details</h3>
            <br />

            <h5>Bachelor Degree Details</h5>
            <br />

            <AvForm
              className="form-horizontal"
              // onValidSubmit={(e, v) => {
              //   handleValidSubmit(e, v);
              // }}
            >
              <div className="form-group">
                <AvField
                  name="highest_education"
                  label="Bachelor Degree "
                  value={user.education_details?.bachelor_degree}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="country_of_education"
                  label="Country Of Education"
                  value={user.education_details?.country_of_education}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="grade_average"
                  label="Bachelor Degree Percentage / CGPA/ Score"
                  value={user.education_details?.bachelor_degree_score}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="school_start"
                  label="Bachelor Degree Subject"
                  value={user.education_details?.bachelor_degree_subject}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="school_end"
                  label="Bachelor Degree Pass Out Year"
                  value={user.education_details?.bachelor_degree_year}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="school_end"
                  label="Bachelor Degree Date As Mentioned On Marksheet"
                  value={user.education_details?.bachelor_degree_date}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                {user.education_details?.bachelor_degree_marksheet ? (
                  <>
                    <Button
                      type="primary"
                      onClick={(e) =>
                        viewDoc(
                          e,
                          user.education_details?.bachelor_degree_marksheet
                        )
                      }
                    >
                      View Marksheet
                    </Button>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <Button
                      type="primary"
                      onClick={(e) =>
                        downloadDoc(
                          e,
                          user.education_details?.bachelor_degree_marksheet,
                          "bachelor"
                        )
                      }
                    >
                      Download Marksheet
                    </Button>
                  </>
                ) : (
                  ""
                )}
                <br />
                <br />
                <h5>Grade 10th Details</h5>
                <br />
                <AvField
                  name="school_address"
                  label="10th Percentage / CGPA/ Score"
                  value={user.education_details?.tenth_score}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="school_zip"
                  label="10th Pass Out Year"
                  value={user.education_details?.tenth_year}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="school_country"
                  label="10th Date As Mentioned On Marksheet"
                  value={user.education_details?.tenth_date}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="school_country"
                  label="School country"
                  value={user.education_details?.school_country}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <br />
                {user.education_details?.tenth_marksheet ? (
                  <>
                    <Button
                      type="primary"
                      onClick={(e) =>
                        viewDoc(e, user.education_details?.tenth_marksheet)
                      }
                    >
                      View Marksheet
                    </Button>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <Button
                      type="primary"
                      onClick={(e) =>
                        downloadDoc(
                          e,
                          user.education_details?.tenth_marksheet,
                          "tenth"
                        )
                      }
                    >
                      Download Marksheet
                    </Button>
                  </>
                ) : (
                  ""
                )}
                <br />
                <br />
                <h5>Grade 12th Details</h5>
                <br />
                <AvField
                  name="school_address"
                  label="12th Percentage / CGPA/ Score"
                  value={user.education_details?.twelfth_score}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="school_zip"
                  label="12th Pass Out Year"
                  value={user.education_details?.twelfth_year}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="school_country"
                  label="12th Date As Mentioned On Marksheet"
                  value={user.education_details?.twelfth_date}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="school_countr"
                  label="12th Subject"
                  value={user.education_details?.twelfth_subject}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <br />
                {user.education_details?.twelfth_marksheet ? (
                  <>
                    <Button
                      type="primary"
                      onClick={(e) =>
                        viewDoc(e, user.education_details?.twelfth_marksheet)
                      }
                    >
                      View Marksheet
                    </Button>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <Button
                      type="primary"
                      onClick={(e) =>
                        downloadDoc(
                          e,
                          user.education_details?.twelfth_marksheet,
                          "twelfth"
                        )
                      }
                    >
                      Download Marksheet
                    </Button>
                  </>
                ) : (
                  ""
                )}
                <br />
                <br />
                <h5>Master's Degree Details</h5>
                <br />
                <AvField
                  name="school_address"
                  label="Master's Degree"
                  value={user.education_details?.master_degree}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="school_address"
                  label="Master's Percentage / CGPA/ Score"
                  value={user.education_details?.master_degree_score}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="school_zip"
                  label="Master's Pass Out Year"
                  value={user.education_details?.master_degree_year}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="school_country"
                  label="Master's Date As Mentioned On Marksheet"
                  value={user.education_details?.master_degree_date}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="school_countr"
                  label="Master's Subject"
                  value={user.education_details?.master_degree_subject}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
              </div>

              {user.education_details?.master_degree_marksheet ? (
                <>
                  <Button
                    type="primary"
                    onClick={(e) =>
                      viewDoc(
                        e,
                        user.education_details?.master_degree_marksheet
                      )
                    }
                  >
                    View Marksheet
                  </Button>
                  &nbsp; &nbsp; &nbsp; &nbsp;
                  <Button
                    type="primary"
                    onClick={(e) =>
                      downloadDoc(
                        e,
                        user.education_details?.master_degree_marksheet,
                        "master's"
                      )
                    }
                  >
                    Download Marksheet
                  </Button>
                </>
              ) : (
                ""
              )}
              <br />
              <br />
            </AvForm>
          </CardBody>
        </Card>
        <Card className="col-sm-11 ms-auto me-auto">
          <CardBody>
            <h4>Test Score</h4>
            <br />
            <AvForm
              className="form-horizontal"
              // onValidSubmit={(e, v) => {
              //   handleValidSubmit(e, v);
              // }}
            >
              <div className="form-group">
                <AvField
                  name="IELTS"
                  label="IELTS Score"
                  value={user.test_score?.ielts_score}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />

                <AvField
                  name="IELTS"
                  label="Certificate Year"
                  value={user.test_score?.ielts_pass_year}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="IELTS"
                  label="Certificate Date As Mentioned on Certificate"
                  value={user.test_score?.ielts_pass_date}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                {user.test_score?.ielts_marksheet ? (
                  <>
                    <Button
                      type="primary"
                      onClick={(e) =>
                        viewDoc(e, user.test_score?.ielts_marksheet)
                      }
                    >
                      View Marksheet
                    </Button>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <Button
                      type="primary"
                      onClick={(e) =>
                        downloadDoc(
                          e,
                          user.test_score?.ielts_marksheet,
                          "ielts"
                        )
                      }
                    >
                      Download Marksheet
                    </Button>
                  </>
                ) : (
                  ""
                )}
                <br />
                <br />
                <AvField
                  name="IELTS"
                  label="GRE Score"
                  value={user.test_score?.gre_score}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />

                <AvField
                  name="IELTS"
                  label="Certificate Year"
                  value={user.test_score?.gre_pass_year}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="IELTS"
                  label="Certificate Date As Mentioned on Certificate"
                  value={user.test_score?.gre_pass_date}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                {user.test_score?.gre_marksheet ? (
                  <>
                    <Button
                      type="primary"
                      onClick={(e) =>
                        viewDoc(e, user.test_score?.gre_marksheet)
                      }
                    >
                      View Marksheet
                    </Button>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <Button
                      type="primary"
                      onClick={(e) =>
                        downloadDoc(e, user.test_score?.gre_marksheet, "gre")
                      }
                    >
                      Download Marksheet
                    </Button>
                  </>
                ) : (
                  ""
                )}
                <br />
                <br />
                <AvField
                  name="IELTS"
                  label="GMAT Score"
                  value={user.test_score?.gmat_score}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />

                <AvField
                  name="IELTS"
                  label="Certificate Year"
                  value={user.test_score?.gmat_pass_year}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="IELTS"
                  label="Certificate Date As Mentioned on Certificate"
                  value={user.test_score?.gmat_pass_date}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                {user.test_score?.gmat_marksheet ? (
                  <>
                    <Button
                      type="primary"
                      onClick={(e) =>
                        viewDoc(e, user.test_score?.gmat_marksheet)
                      }
                    >
                      View Marksheet
                    </Button>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <Button
                      type="primary"
                      onClick={(e) =>
                        downloadDoc(e, user.test_score?.gmat_marksheet, "gmat")
                      }
                    >
                      Download Marksheet
                    </Button>
                  </>
                ) : (
                  ""
                )}
                <br />
                <br />

                <AvField
                  name="IELTS"
                  label="TESTAS Score"
                  value={user.test_score?.testas_score}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />

                <AvField
                  name="IELTS"
                  label="Certificate Year"
                  value={user.test_score?.testas_pass_year}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="IELTS"
                  label="Certificate Date As Mentioned on Certificate"
                  value={user.test_score?.testas_pass_date}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />

                {user.test_score?.testas_marksheet ? (
                  <>
                    <Button
                      type="primary"
                      onClick={(e) =>
                        viewDoc(e, user.test_score?.testas_marksheet)
                      }
                    >
                      View Marksheet
                    </Button>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <Button
                      type="primary"
                      onClick={(e) =>
                        downloadDoc(
                          e,
                          user.test_score?.testas_marksheet,
                          "testas"
                        )
                      }
                    >
                      Download Marksheet
                    </Button>
                  </>
                ) : (
                  ""
                )}
                <br />
                <br />

                <AvField
                  name="IELTS"
                  label="NEET Score"
                  value={user.test_score?.neet_score}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />

                <AvField
                  name="IELTS"
                  label="Certificate Year"
                  value={user.test_score?.neet_pass_year}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="IELTS"
                  label="Certificate Date As Mentioned on Certificate"
                  value={user.test_score?.neet_pass_date}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                {user.test_score?.neet_marksheet ? (
                  <>
                    <Button
                      type="primary"
                      onClick={(e) =>
                        viewDoc(e, user.test_score?.neet_marksheet)
                      }
                    >
                      View Marksheet
                    </Button>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <Button
                      type="primary"
                      onClick={(e) =>
                        downloadDoc(e, user.test_score?.neet_marksheet, "neet")
                      }
                    >
                      Download Marksheet
                    </Button>
                  </>
                ) : (
                  ""
                )}
                <br />
                <br />
                <AvField
                  name="IELTS"
                  label="IIT Mains Score"
                  value={user.test_score?.iit_mains_score}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />

                <AvField
                  name="IELTS"
                  label="Certificate Year"
                  value={user.test_score?.iit_mains_pass_year}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="IELTS"
                  label="Certificate Date As Mentioned on Certificate"
                  value={user.test_score?.iit_mains_pass_date}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                {user.test_score?.iit_mains_marksheet ? (
                  <>
                    <Button
                      type="primary"
                      onClick={(e) =>
                        viewDoc(e, user.test_score?.iit_mains_marksheet)
                      }
                    >
                      View Marksheet
                    </Button>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <Button
                      type="primary"
                      onClick={(e) =>
                        downloadDoc(
                          e,
                          user.test_score?.iit_mains_marksheet,
                          "iit_mains"
                        )
                      }
                    >
                      Download Marksheet
                    </Button>
                  </>
                ) : (
                  ""
                )}
                <br />
                <br />
                <AvField
                  name="IELTS"
                  label="IIT Advanced Score"
                  value={user.test_score?.iit_advance_score}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />

                <AvField
                  name="IELTS"
                  label="Certificate Year"
                  value={user.test_score?.iit_advance_pass_year}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="IELTS"
                  label="Certificate Date As Mentioned on Certificate"
                  value={user.test_score?.iit_advance_pass_date}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />

                {user.test_score?.iit_advance_marksheet ? (
                  <>
                    <Button
                      type="primary"
                      onClick={(e) =>
                        viewDoc(e, user.test_score?.iit_advance_marksheet)
                      }
                    >
                      View Marksheet
                    </Button>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <Button
                      type="primary"
                      onClick={(e) =>
                        downloadDoc(
                          e,
                          user.test_score?.iit_advance_marksheet,
                          "iit_advance"
                        )
                      }
                    >
                      Download Marksheet
                    </Button>
                  </>
                ) : (
                  ""
                )}
                <br />
                <br />
                <AvField
                  name="IELTS"
                  label="Level A1 Score"
                  value={user.test_score?.a1_score}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />

                <AvField
                  name="IELTS"
                  label="Certificate Year"
                  value={user.test_score?.a1_pass_year}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="IELTS"
                  label="Certificate Date As Mentioned on Certificate"
                  value={user.test_score?.a1_pass_date}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                {user.test_score?.a1_certificate ? (
                  <>
                    <Button
                      type="primary"
                      onClick={(e) =>
                        viewDoc(e, user.test_score?.a1_certificate)
                      }
                    >
                      View Marksheet
                    </Button>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <Button
                      type="primary"
                      onClick={(e) =>
                        downloadDoc(e, user.test_score?.a1_certificate, "a1")
                      }
                    >
                      Download Marksheet
                    </Button>
                  </>
                ) : (
                  ""
                )}
                <br />
                <br />
                <AvField
                  name="IELTS"
                  label="Level A2 Score"
                  value={user.test_score?.a2_score}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />

                <AvField
                  name="IELTS"
                  label="Certificate Year"
                  value={user.test_score?.a2_pass_year}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="IELTS"
                  label="Certificate Date As Mentioned on Certificate"
                  value={user.test_score?.a2_pass_date}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                {user.test_score?.a2_certificate ? (
                  <>
                    <Button
                      type="primary"
                      onClick={(e) =>
                        viewDoc(e, user.test_score?.a2_certificate)
                      }
                    >
                      View Marksheet
                    </Button>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <Button
                      type="primary"
                      onClick={(e) =>
                        downloadDoc(e, user.test_score?.a2_certificate, "a2")
                      }
                    >
                      Download Marksheet
                    </Button>
                  </>
                ) : (
                  ""
                )}
                <br />
                <br />
                <AvField
                  name="IELTS"
                  label="Level B1 Score"
                  value={user.test_score?.b1_score}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />

                <AvField
                  name="IELTS"
                  label="Certificate Year"
                  value={user.test_score?.b1_pass_year}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="IELTS"
                  label="Certificate Date As Mentioned on Certificate"
                  value={user.test_score?.b1_pass_date}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                {user.test_score?.b1_certificate ? (
                  <>
                    <Button
                      type="primary"
                      onClick={(e) =>
                        viewDoc(e, user.test_score?.b1_certificate)
                      }
                    >
                      View Marksheet
                    </Button>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <Button
                      type="primary"
                      onClick={(e) =>
                        downloadDoc(e, user.test_score?.b1_certificate, "b1")
                      }
                    >
                      Download Marksheet
                    </Button>
                  </>
                ) : (
                  ""
                )}
                <br />
                <br />
                <AvField
                  name="IELTS"
                  label="Level B2 Score"
                  value={user.test_score?.b2_score}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />

                <AvField
                  name="IELTS"
                  label="Certificate Year"
                  value={user.test_score?.b2_pass_year}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="IELTS"
                  label="Certificate Date As Mentioned on Certificate"
                  value={user.test_score?.b2_pass_date}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                {user.test_score?.b2_certificate ? (
                  <>
                    <Button
                      type="primary"
                      onClick={(e) =>
                        viewDoc(e, user.test_score?.b2_certificate)
                      }
                    >
                      View Marksheet
                    </Button>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <Button
                      type="primary"
                      onClick={(e) =>
                        downloadDoc(e, user.test_score?.b2_certificate, "b2")
                      }
                    >
                      Download Marksheet
                    </Button>
                  </>
                ) : (
                  ""
                )}
                <br />
                <br />
                <AvField
                  name="IELTS"
                  label="Level C1 Score"
                  value={user.test_score?.c1_score}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />

                <AvField
                  name="IELTS"
                  label="Certificate Year"
                  value={user.test_score?.c1_pass_year}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="IELTS"
                  label="Certificate Date As Mentioned on Certificate"
                  value={user.test_score?.c1_pass_date}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                {user.test_score?.c1_certificate ? (
                  <>
                    <Button
                      type="primary"
                      onClick={(e) =>
                        viewDoc(e, user.test_score?.c1_certificate)
                      }
                    >
                      View Marksheet
                    </Button>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <Button
                      type="primary"
                      onClick={(e) =>
                        downloadDoc(e, user.test_score?.c1_certificate, "c1")
                      }
                    >
                      Download Marksheet
                    </Button>
                  </>
                ) : (
                  ""
                )}
                <br />
                <br />
                <AvField
                  name="IELTS"
                  label="Level C2 Score"
                  value={user.test_score?.c2_score}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />

                <AvField
                  name="IELTS"
                  label="Certificate Year"
                  value={user.test_score?.c2_pass_year}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                <AvField
                  name="IELTS"
                  label="Certificate Date As Mentioned on Certificate"
                  value={user.test_score?.c2_pass_date}
                  className="form-control"
                  type="text"
                  disabled
                />
                <br />
                {user.test_score?.c2_certificate ? (
                  <>
                    <Button
                      type="primary"
                      onClick={(e) =>
                        viewDoc(e, user.test_score?.c2_certificate)
                      }
                    >
                      View Marksheet
                    </Button>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <Button
                      type="primary"
                      onClick={(e) =>
                        downloadDoc(e, user.test_score?.c2_certificate, "c2")
                      }
                    >
                      Download Marksheet
                    </Button>
                  </>
                ) : (
                  ""
                )}
                <br />
                <br />
              </div>
            </AvForm>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default UserInsight;
