import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { UncontrolledAlert } from "reactstrap";
import {
  addCourseExperience,
  getCourseExperience,
  updateCourseExperience,
} from "../../repositories/DropdownRepository";
import { useHistory } from "react-router-dom";
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import { set } from "lodash";

const AddCourseExperience = (props) => {
  const [exp, setExp] = useState("");
  const [adminId, setAdminId] = useState("");
  const [sizeId, setSizeId] = useState("");
  const [button, setButton] = useState("Submit");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const history = useHistory();
  const [type, setType] = useState("");

  useEffect(async () => {
    let typ = window.location.pathname.split("/").pop();
    if (typ !== "new") {
      let res = await getCourseExperience({ course_experience_id: typ });
      setExp(res.data.course_experience);
      setButton("Update");
    }
    setType(typ);
  }, [props.success, exp]);

  async function handleValidSubmit(event, values) {
    if (type !== "new") {
      setExp(values.experience);
      console.log("edit");
      let res = await updateCourseExperience({
        course_experience: values.experience,
        course_experience_id: type,
      });
      if (res.status == 1) {
        setSuccess(res.message);

        setTimeout(() => {
          setSuccess(null);
          setExp("");
          history.push("/course-experience-list");
        }, 3000);
      } else {
        setError(res.message);
        setTimeout(() => {
          setError(null);
        }, 5000);
      }
    } else {
      setExp(values.experience);
      let res = await addCourseExperience({
        course_experience: values.experience,
      });
      if (res.status == 1) {
        setSuccess(res.message);

        setTimeout(() => {
          setSuccess(null);
          setExp("");
          history.push("/course-experience-list");
        }, 3000);
      } else {
        setError(res.message);
        setTimeout(() => {
          setError(null);
        }, 5000);
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <Col lg="12">
            {error ? (
              <UncontrolledAlert color="danger" isOpen={true} dismissible>
                {error}
              </UncontrolledAlert>
            ) : null}
            {success ? (
              <UncontrolledAlert color="success" isOpen={true} dismissible>
                {success}
              </UncontrolledAlert>
            ) : null}
          </Col>
        </Row>

        <Row className="page-content ">
          <h4 className="card-title mb-4 ms-5 ">Add Work Experience</h4>

          <Card className="col-sm-11 ms-auto me-auto">
            <CardBody>
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v);
                }}
              >
                <div className="form-group">
                  <AvField
                    name="experience"
                    label="Work Experience"
                    value={exp}
                    className="form-control"
                    placeholder="Enter Work Experience"
                    type="text"
                    required
                  />
                  <br />
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="primary">
                    {button}
                  </Button>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default AddCourseExperience;
