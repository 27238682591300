import Repository, { baseUrl } from "./Repository";

export function getAdminProfile(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      console.log("base", baseUrl);
      const response = await Repository.post(`${baseUrl}/get_admin_profile`);

      return resolve(response.data);
    } catch (error) {}
  });
}

export function updateAdminProfile(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      console.log(payload, "payload");
      const response = await Repository.post(
        `${baseUrl}/update_admin_profile`,
        payload
      );

      return resolve(response.data);
    } catch (error) {}
  });
}
export function changeAdminPassword(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      console.log(payload, "payload");
      const response = await Repository.post(
        `${baseUrl}/change_admin_password`,
        payload
      );

      return resolve(response.data);
    } catch (error) {}
  });
}

export function getUserList(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(`${baseUrl}/user_list`, payload);

      return resolve(response.data);
    } catch (error) {}
  });
}

export function loginAdmin(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(`${baseUrl}/admin_login`, payload);

      return resolve(response.data);
    } catch (error) {}
  });
}

export function changeUserStatus(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/change_user_status`,
        payload
      );

      return resolve(response.data);
    } catch (error) {}
  });
}

export function getUserProfile(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/get_user_profile`,
        payload
      );

      return resolve(response.data);
    } catch (error) {}
  });
}
export function getUniversityProfile(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/get_universities_profile`,
        payload
      );

      return resolve(response.data);
    } catch (error) {}
  });
}
export function getCourse(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(`${baseUrl}/get_course`, payload);

      return resolve(response.data);
    } catch (error) {}
  });
}
export function addGuestUser(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/add_guest_user`,
        payload
      );

      return resolve(response.data);
    } catch (error) {}
  });
}
export function getGuestUser(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/get_guest_user`,
        payload
      );

      return resolve(response.data);
    } catch (error) {}
  });
}
export function deleteGuestUser(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/delete_guest_user`,
        payload
      );

      return resolve(response.data);
    } catch (error) {}
  });
}
export function getApplication(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/get_application`,
        payload
      );

      return resolve(response.data);
    } catch (error) {}
  });
}
export function updateApplication(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/update_application`,
        payload
      );

      return resolve(response.data);
    } catch (error) {}
  });
}
