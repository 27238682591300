import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { UncontrolledAlert } from "reactstrap";
import {
  addDescription,
  getDescription,
  updateDescription,
} from "../../repositories/AttributeRepository";
import { getCategory } from "../../repositories/settingRepository";
import { getCountry } from "../../repositories/locationRepository";
import { useHistory } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import ReactPaginate from "react-paginate";
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import { set } from "lodash";

const AddDescription = (props) => {
  const [des, setDes] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [countryId, setCountryId] = useState([]);
  const [courseId, setCuserseId] = useState([]);
  const [selectCountry, setSelectedCountry] = useState("");
  const [selectCourse, setSelectedCourse] = useState("");

  const [adminId, setAdminId] = useState("");
  const [refresh, setRefresh] = useState(false);

  const [sizeId, setSizeId] = useState("");
  const [button, setButton] = useState("Submit");
  const [error, setError] = useState(null);
  const [currentpage, setCurrentPage] = useState(1);

  const [success, setSuccess] = useState(null);
  const history = useHistory();
  const [type, setType] = useState("");

  useEffect(async () => {
    let typ = window.location.pathname.split("/").pop();
    if (typ !== "new") {
      let res = await getDescription({ description_id: typ });

      setDes(res.data.description);
      setSelectedCountry(res.data.country_id);
      setSelectedCourse(res.data.category_id);
      setButton("Update");
    }
    setType(typ);
  }, [props.success]);

  useEffect(() => {
    GetCountry();
    GetCourse();
    setRefresh(false);
  }, [currentpage, refresh]);

  const GetCountry = async () => {
    let res = await getCountry({
      is_all: true,
    });
    setCountryList(res.data);
  };
  const GetCourse = async () => {
    let res = await getCategory({
      is_all: true,
    });
    setCourseList(res.data);
  };

  const onchangeCountryId = (e) => {
    setSelectedCountry(e.target.value);
  };
  const onchangeCourseId = (e) => {
    setSelectedCourse(e.target.value);
  };
  async function handleValidSubmit(event, values) {
    if (type !== "new") {
      let res = await updateDescription({
        description: des,
        country_id: selectCountry,
        category_id: selectCourse,
        description_id: type,
      });
      if (res.status == 1) {
        setSuccess(res.message);
        setTimeout(() => {
          setSuccess(null);
          setDes("");
          setSelectedCountry("");
          setSelectedCourse("");
          history.push("/description-list");
        }, 3000);
      } else {
        setError(res.message);
        setTimeout(() => {
          setError(null);
        }, 5000);
      }
    } else {
      let res = await addDescription({
        country_id: selectCountry,
        category_id: selectCourse,
        description: des,
      });
      console.log("add wala", res);
      if (res.status == 1) {
        setSuccess(res.message);

        setTimeout(() => {
          setSuccess(null);
          setDes("");
          setSelectedCountry("");
          setSelectedCourse("");
          history.push("/description-list");
        }, 3000);
      } else {
        setError(res.message);
        setTimeout(() => {
          setError(null);
        }, 5000);
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content"></div>
      <Row>
        <Col lg="12">
          {error ? (
            <UncontrolledAlert color="danger" isOpen={true} dismissible>
              {error}
            </UncontrolledAlert>
          ) : null}
          {success ? (
            <UncontrolledAlert color="success" isOpen={true} dismissible>
              {success}
            </UncontrolledAlert>
          ) : null}
        </Col>
      </Row>
      <Row className="p-3 px-3">
        <h4 className="card-title mb-4 ms-5">Add Description</h4>

        <Card className="col-sm-11 ms-auto me-auto">
          <CardBody>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              <div className="form-group">
                <AvField
                  className="form-control"
                  type="select"
                  // value={selectState}
                  name="select"
                  label="Country Name"
                  value={selectCountry}
                  helpMessage="Choose Your country Here"
                  onChange={onchangeCountryId}
                >
                  <option>---Please select country---</option>
                  {countryList?.map((ele, index) => {
                    //   console.log("test", ele._id, "select", selectState);
                    return ele._id === selectCountry ? (
                      <option key={index} value={ele._id} selected>
                        {ele.country_name}
                      </option>
                    ) : (
                      <option key={index} value={ele._id}>
                        {ele.country_name}
                      </option>
                    );
                  })}
                </AvField>
                <br></br>
                <AvField
                  className="form-control"
                  type="select"
                  // value={selectState}
                  name="select1"
                  label="Course Name"
                  value={selectCourse}
                  helpMessage="Choose Your course Here"
                  onChange={onchangeCourseId}
                >
                  <option>---Please select course---</option>
                  {courseList?.map((ele, index) => {
                    //   console.log("test", ele._id, "select", selectState);
                    return ele._id === selectCourse ? (
                      <option key={index} value={ele._id} selected>
                        {ele.category_name}
                      </option>
                    ) : (
                      <option key={index} value={ele._id}>
                        {ele.category_name}
                      </option>
                    );
                  })}
                </AvField>
                <br></br>
                <CKEditor
                  editor={ClassicEditor}
                  data={des}
                  config={{ placeholder: "enter description ..." }}
                  onChange={(event, editor) => {
                    const data = editor.getData();

                    setDes(data);
                  }}
                />
                <br />
              </div>
              <div className="text-center mt-4">
                <Button type="submit" color="primary">
                  {button}
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </Card>
      </Row>
    </React.Fragment>
  );
};

export default AddDescription;
