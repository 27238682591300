import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { UncontrolledAlert } from "reactstrap";
import { changeAdminPassword } from "../../repositories/adminRepository";
import { useHistory } from "react-router-dom";
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import { set } from "lodash";

const ChangePassword = (props) => {
  const [old, setOld] = useState("");
  const [newp, setNewp] = useState("");
  const [button, setButton] = useState("Submit");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const history = useHistory();
  const [type, setType] = useState("");

  useEffect(async () => {}, [props.success]);

  async function handleValidSubmit(event, values) {
    setOld(values.old_password);
    setNewp(values.new_password);
    let res = await changeAdminPassword({
      old_password: values.old_password,
      new_password: values.new_password,
    });
    if (res.status == 1) {
      setSuccess(res.message);

      setTimeout(() => {
        setSuccess(null);
        history.push("/profile");
      }, 3000);
    } else {
      setError(res.message);
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <Col lg="12">
            {error ? (
              <UncontrolledAlert color="danger" isOpen={true} dismissible>
                {error}
              </UncontrolledAlert>
            ) : null}
            {success ? (
              <UncontrolledAlert color="success" isOpen={true} dismissible>
                {success}
              </UncontrolledAlert>
            ) : null}
          </Col>
        </Row>
        <div className="page-content">
          <Card className="col-sm-11 ms-auto me-auto">
            <CardBody>
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v);
                }}
              >
                <div className="form-group">
                  <AvField
                    name="old_password"
                    label="Old Password"
                    value={old}
                    className="form-control"
                    type="password"
                    required
                  />
                  <br />
                  <AvField
                    name="new_password"
                    label="New Password"
                    value={newp}
                    className="form-control"
                    type="password"
                    required
                  />
                  <br />
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="primary">
                    Change Password
                  </Button>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ChangePassword;
