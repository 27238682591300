import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { UncontrolledAlert } from "reactstrap";
import {
  getAdminProfile,
  changeAdminPassword,
  updateAdminProfile,
} from "../../repositories/adminRepository";
import { useHistory, Link } from "react-router-dom";
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import { set } from "lodash";

const AdminProfile = (props) => {
  const [email, setEmail] = useState("");
  const [adminId, setAdminId] = useState("");
  const [sizeId, setSizeId] = useState("");
  const [button, setButton] = useState("Submit");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const history = useHistory();
  const [type, setType] = useState("");

  useEffect(async () => {
    let res = await getAdminProfile();
    setEmail(res?.data?.email);
  }, [props.success, email]);

  async function handleValidSubmit(event, values) {
    setEmail(values.email);
    let res = await updateAdminProfile({
      email: values.email,
    });
    if (res.status == 1) {
      setSuccess(res.message);

      setTimeout(() => {
        setSuccess(null);
      }, 3000);
    } else {
      setError(res.message);
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Row className=" ">
          <Col lg="11">
            <Link to="/change-password">
              <Button color="primary" style={{ float: "right" }}>
                Change Password
              </Button>
            </Link>
          </Col>
        </Row>
        <br />
        <Row>
          <Col lg="12">
            {error ? (
              <UncontrolledAlert color="danger" isOpen={true} dismissible>
                {error}
              </UncontrolledAlert>
            ) : null}
            {success ? (
              <UncontrolledAlert color="success" isOpen={true} dismissible>
                {success}
              </UncontrolledAlert>
            ) : null}
          </Col>
        </Row>

        <Card className="col-sm-11 ms-auto me-auto">
          <CardBody>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              <div className="form-group">
                <AvField
                  name="email"
                  label="Email"
                  value={email}
                  className="form-control"
                  type="text"
                  required
                />
                <br />
              </div>
              <div className="text-center mt-4">
                <Button type="submit" color="primary">
                  Update
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default AdminProfile;
