import React from "react";
import {
  getApplication,
  updateApplication,
} from "../../repositories/adminRepository";
import { useState, useEffect } from "react";
import { Table, Button, Row, Col, Card, CardBody } from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";

import ReactPaginate from "react-paginate";
import { UncontrolledAlert } from "reactstrap";
import { useHistory } from "react-router-dom";
// import { AiFillEdit } from "react-icons/ai";
// import { AiOutlineDelete } from "react-icons/ai";
import { Link } from "react-router-dom";

export default function ApplicationList() {
  const [appList, setAppList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [currentpage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const history = useHistory();

  let pagesize = 10;
  useEffect(() => {
    GetApp();
    setRefresh(false);
  }, [currentpage, refresh]);

  const GetApp = async () => {
    let res = await getApplication({
      is_admin: true,
      page: currentpage,
      pagesize: pagesize,
    });

    setAppList(res.data);
    setDataCount(res.count);
    let pageCount1 = Math.ceil(res.count / pagesize);
    setPageCount(pageCount1);
  };

  const approveApplication = async (value) => {
    let res = await updateApplication({
      application_id: value,
      status: "approved",
    });

    if (res.status == 1) {
      setRefresh(true);
      setSuccess(res.message);
      setTimeout(() => {
        setSuccess(null);
      }, 5000);
    } else {
      setError(res.message);
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  };
  const rejectApplication = async (value) => {
    let res = await updateApplication({
      application_id: value,
      status: "reject",
    });

    if (res.status == 1) {
      setRefresh(true);
      setSuccess(res.message);
      setTimeout(() => {
        setSuccess(null);
      }, 5000);
    } else {
      setError(res.message);
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  };

  const onPageSubmit = (value) => {
    setCurrentPage(value.selected + 1);
    console.log("value", value.selected + 1);
  };
  const deleteApplication = async (value) => {
    let res = await updateApplication({
      application_id: value,
      is_delete: true,
    });
    if (res.status == 1) {
      setRefresh(true);
      setSuccess(res.message);
      setTimeout(() => {
        setSuccess(null);
      }, 5000);
    } else {
      setError(res.message);
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
    console.log(res);
  };

  return (
    <>
      <div className="page-content">
        <Row>
          <h4>Application List</h4>
        </Row>
        <Card>
          <CardBody>
            <Row>
              <Col lg="12">
                {error ? (
                  <UncontrolledAlert color="danger" isOpen={true} dismissible>
                    {error}
                  </UncontrolledAlert>
                ) : null}
                {success ? (
                  <UncontrolledAlert color="success" isOpen={true} dismissible>
                    {success}
                  </UncontrolledAlert>
                ) : null}
              </Col>
            </Row>
            <div className="table-responsive">
              <Table hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Course Name</th>
                    <th>Student Name</th>
                    <th>University Name</th>
                    <th>Status</th>

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {appList?.map((ele, index) => {
                    return (
                      <tr key={ele._id}>
                        <th scope="row">{index + 1}</th>

                        <td>{ele?.course_id?.course_name}</td>
                        <td>
                          {ele.student_id?.name} &nbsp; &nbsp;
                          <Link
                            to={`/user-detail/${ele.student_id?._id}`}
                            className="pe-3"
                          >
                            <i
                              title="See Student Profile"
                              class="fas fa-eye fs-5"
                            ></i>
                          </Link>
                        </td>

                        <td>
                          {ele.university_id?.name}
                          &nbsp; &nbsp;
                          <Link
                            to={`/university-detail/${ele.university_id?._id}`}
                            className="pe-3"
                          >
                            <i
                              title="See University Profile"
                              class="fas fa-eye fs-5"
                            ></i>
                          </Link>
                        </td>
                        <td>{ele?.status}</td>

                        <div>
                          {ele?.status === "pending" ? (
                            <>
                              {" "}
                              <Link>
                                <i
                                  title="approve"
                                  className="  fas fa-check-circle fs-5"
                                  onClick={() => approveApplication(ele._id)}
                                />
                              </Link>
                              &nbsp;
                              <Link>
                                <i
                                  title="reject"
                                  className="   ri-honor-of-kings-line"
                                  onClick={() => rejectApplication(ele._id)}
                                />
                              </Link>
                            </>
                          ) : (
                            ""
                          )}
                          &nbsp;
                          <Link>
                            <i
                              className=" fas fa-trash-alt"
                              size={20}
                              onClick={(e) => {
                                deleteApplication(ele._id);
                              }}
                            />
                          </Link>
                        </div>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <div style={{ display: "flex" }}>
              <ReactPaginate
                breakLabel="..."
                nextLabel="next >"
                onPageChange={onPageSubmit}
                pageRangeDisplayed={5}
                pageCount={pageCount ? pageCount : 1}
                previousLabel="< previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}

                //   renderOnZeroPageCount={null}
              />
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
}
