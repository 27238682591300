import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardText,
  CardTitle,
} from "reactstrap";
import { UncontrolledAlert } from "reactstrap";
import {
  addBlog,
  updateBlog,
  getBlog,
} from "../../repositories/settingRepository";
import { useHistory } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import { set } from "lodash";

const AddBlog = (props) => {
  const [title, setTitle] = useState("");
  const [subtitle, setSubTitle] = useState("");
  const [content, setContent] = useState("");
  const [file, setFile] = useState(null);
  const [adminId, setAdminId] = useState("");
  const [sizeId, setSizeId] = useState("");
  const [button, setButton] = useState("Submit");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const history = useHistory();
  const [type, setType] = useState("");

  useEffect(async () => {
    let typ = window.location.pathname.split("/").pop();
    if (typ !== "new") {
      let res = await getBlog({ blog_id: typ });
      setTitle(res.data.title);
      setSubTitle(res.data.subtitle);
      setContent(res.data.content);
      setButton("Update");
    }
    setType(typ);
  }, [props.success]);

  const fileFunction = (e) => {
    setFile(e.target.files[0]);
  };

  async function handleValidSubmit(event, values) {
    let formD = new FormData();
    if (type !== "new") {
      formD.append("title", values.title);
      formD.append("subtitle", values.subtitle);
      formD.append("content", content);
      formD.append("file", file);
      formD.append("blog_id", type);
      let res = await updateBlog(formD);
      if (res.status == 1) {
        setSuccess(res.message);

        setTimeout(() => {
          setSuccess(null);
          setTitle("");
          setSubTitle("");
          setContent("");
          history.push("/blog");
        }, 3000);
      } else {
        setError(res.message);
        setTimeout(() => {
          setError(null);
        }, 5000);
      }
    } else {
      formD.append("title", values.title);
      formD.append("subtitle", values.subtitle);
      formD.append("content", content);
      formD.append("file", file);

      let res = await addBlog(formD);
      if (res.status == 1) {
        setSuccess(res.message);

        setTimeout(() => {
          setSuccess(null);
          setTitle("");
          setSubTitle("");
          setContent("");
          history.push("/blog");
        }, 3000);
      } else {
        setError(res.message);
        setTimeout(() => {
          setError(null);
        }, 5000);
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <Col lg="12">
            {error ? (
              <UncontrolledAlert color="danger" isOpen={true} dismissible>
                {error}
              </UncontrolledAlert>
            ) : null}
            {success ? (
              <UncontrolledAlert color="success" isOpen={true} dismissible>
                {success}
              </UncontrolledAlert>
            ) : null}
          </Col>
        </Row>

        <h4 className="card-title mb-4">Add Blog</h4>

        <Card>
          <CardBody>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              <div className="form-group">
                <AvField
                  name="title"
                  label="Title"
                  value={title}
                  className="form-control"
                  placeholder="Enter Title"
                  type="text"
                  required
                />

                <br />
                <AvField
                  name="subtitle"
                  label="Subtitle"
                  value={subtitle}
                  className="form-control"
                  placeholder="Enter Subtitle"
                  type="text"
                  required
                />

                <br />
                <AvField
                  name="image"
                  label="Cover Image"
                  className="form-control"
                  type="file"
                  onChange={fileFunction}
                />

                <br />

                <CKEditor
                  editor={ClassicEditor}
                  data={content}
                  config={{ placeholder: "insert blog content here" }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setContent(data);
                  }}
                  required
                />
              </div>

              <div className="text-center mt-4">
                <Button type="submit" color="primary">
                  {button}
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default AddBlog;
