import React from "react";
import { getCourse } from "../../repositories/adminRepository";
import { useState, useEffect } from "react";
import { Table, Button, Row, Col, Card, CardBody } from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import moment from "moment";

import ReactPaginate from "react-paginate";
import { UncontrolledAlert } from "reactstrap";
import { useHistory } from "react-router-dom";
// import { AiFillEdit } from "react-icons/ai";
// import { AiOutlineDelete } from "react-icons/ai";
import { Link } from "react-router-dom";

export default function CourseList() {
  const [courseList, setCourseList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [currentpage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [type, setType] = useState("");

  const history = useHistory();

  let pagesize = 10;
  useEffect(async () => {
    let typ = window.location.pathname.split("/").pop();
    if (typ !== "new") {
      let res = await getCourse({
        user_id: typ,
        page: currentpage,
        pagesize: pagesize,
      });
      if (res.status === 1) {
        setCourseList(res?.data);
        setDataCount(res?.count);
        let pageCount1 = Math.ceil(res?.count / pagesize);
        setPageCount(pageCount1);
      }
    }
    setType(typ);
  }, [currentpage]);

  const onPageSubmit = (value) => {
    setCurrentPage(value.selected + 1);
    console.log("value", value.selected + 1);
  };

  return (
    <>
      <div className="page-content">
        <Row>
          <h4>Courses List</h4>
        </Row>
        <Col lg="12">
          {error ? (
            <UncontrolledAlert color="danger" isOpen={true} dismissible>
              {error}
            </UncontrolledAlert>
          ) : null}
          {success ? (
            <UncontrolledAlert color="success" isOpen={true} dismissible>
              {success}
            </UncontrolledAlert>
          ) : null}
        </Col>
        {courseList && courseList.length > 0
          ? courseList?.map((ele, index) => {
              return (
                <Card>
                  <CardBody style={{ paddingLeft: "40px" }}>
                    <h5>
                      <strong>{ele?.course_name}</strong>
                    </h5>
                    <br />
                    <Row>
                      <Col lg="3">
                        <h6>Course Type</h6>
                      </Col>
                      <Col lg="3">{ele?.type?.attribute_value}</Col>
                    </Row>
                    <Row>
                      <Col lg="3">
                        <h6>Total Course Fees</h6>
                      </Col>
                      <Col lg="3">{ele?.total_course_fees}</Col>
                    </Row>

                    <Row>
                      <Col lg="3">
                        <h6>Course Level</h6>
                      </Col>
                      <Col lg="3">{ele?.course_level?.attribute_value}</Col>
                    </Row>
                    <Row>
                      <Col lg="3">
                        <h6>Course Programm</h6>
                      </Col>
                      <Col lg="3">{ele?.course_program?.attribute_value}</Col>
                    </Row>
                    <Row>
                      <Col lg="3">
                        <h6>Course Duration</h6>
                      </Col>
                      <Col lg="3">{ele?.course_duration}</Col>
                    </Row>
                    <Row>
                      <Col lg="3">
                        <h6>Intake/Addmission</h6>
                      </Col>
                      <Col lg="3">{ele?.admission}</Col>
                    </Row>
                    <Row>
                      <Col lg="3">
                        <h6>Work Experience</h6>
                      </Col>
                      <Col lg="3">{ele?.work_experience?.attribute_value}</Col>
                    </Row>
                    <Row>
                      <Col lg="3">
                        <h6>Eligiblity</h6>
                      </Col>
                      <Col lg="3">{ele?.eligibility}</Col>
                    </Row>
                    <Row>
                      <Col lg="3">
                        <h6>Course Language</h6>
                      </Col>
                      <Col lg="3">{ele?.course_language?.language_name}</Col>
                    </Row>
                    <Row>
                      <Col lg="3">
                        <h6>Required Degree</h6>
                      </Col>
                      <Col lg="3">{ele?.required_degress?.attribute_value}</Col>
                    </Row>

                    <div className="col-sm-6 mt-3">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Year</th>
                            <th scope="col">Fees</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ele?.fees.length > 0
                            ? ele?.fees.map((ele, index) => {
                                return (
                                  <tr row="1">
                                    <td>{ele.year} year</td>
                                    <td>{ele.fees}</td>
                                  </tr>
                                );
                              })
                            : ""}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              );
            })
          : ""}

        <div style={{ display: "flex" }}>
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={onPageSubmit}
            pageRangeDisplayed={5}
            pageCount={pageCount ? pageCount : 1}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}

            //   renderOnZeroPageCount={null}
          />
        </div>
      </div>
    </>
  );
}
