import Repository, { baseUrl } from "./Repository";

export function addFaq(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(`${baseUrl}/add_faq`, payload);
      return resolve(response.data);
    } catch (error) {}
  });
}
export function getFaq(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(`${baseUrl}/get_faq`, payload);
      return resolve(response.data);
    } catch (error) {}
  });
}
export function updateFaq(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(`${baseUrl}/update_faq`, payload);
      return resolve(response.data);
    } catch (error) {}
  });
}
export function getContactUs(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/get_contact_us`,
        payload
      );
      return resolve(response.data);
    } catch (error) {}
  });
}
export function updateContactUs(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/update_contact_us`,
        payload
      );
      return resolve(response.data);
    } catch (error) {}
  });
}
export function getAboutUs(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/get_about_us`,
        payload
      );
      return resolve(response.data);
    } catch (error) {}
  });
}
export function updateAboutUs(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/update_about_us`,
        payload
      );
      return resolve(response.data);
    } catch (error) {}
  });
}
export function updatePrivacyPolicy(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/update_privacy_policy`,
        payload
      );
      return resolve(response.data);
    } catch (error) {}
  });
}
export function getPrivacyPolicy(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/get_privacy_policy`,
        payload
      );
      return resolve(response.data);
    } catch (error) {}
  });
}
export function updateWorkingPolicy(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/update_working_policy`,
        payload
      );
      return resolve(response.data);
    } catch (error) {}
  });
}
export function getWorkingPolicy(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/get_working_policy`,
        payload
      );
      return resolve(response.data);
    } catch (error) {}
  });
}
export function getTermAndCondition(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/get_term_and_condition`,
        payload
      );
      return resolve(response.data);
    } catch (error) {}
  });
}
export function updateTermAndCondition(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/update_term_and_condition`,
        payload
      );
      return resolve(response.data);
    } catch (error) {}
  });
}
export function addLanguage(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/add_language`,
        payload
      );
      return resolve(response.data);
    } catch (error) {}
  });
}
export function getLanguage(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/get_language`,
        payload
      );
      return resolve(response.data);
    } catch (error) {}
  });
}
export function updateLanguage(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/update_language`,
        payload
      );
      return resolve(response.data);
    } catch (error) {}
  });
}

export function addCategory(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/add_category`,
        payload
      );
      return resolve(response.data);
    } catch (error) {}
  });
}
export function getCategory(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/get_category`,
        payload
      );
      return resolve(response.data);
    } catch (error) {}
  });
}
export function updateCategory(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/update_category`,
        payload
      );
      return resolve(response.data);
    } catch (error) {}
  });
}
export function getLogo(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(`${baseUrl}/get_logo`, payload);
      return resolve(response.data);
    } catch (error) {}
  });
}
export function updateLogo(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(`${baseUrl}/update_logo`, payload);
      return resolve(response.data);
    } catch (error) {}
  });
}
export function addBlog(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(`${baseUrl}/add_blog`, payload);
      return resolve(response.data);
    } catch (error) {}
  });
}
export function getBlog(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(`${baseUrl}/get_blog`, payload);
      return resolve(response.data);
    } catch (error) {}
  });
}
export function updateBlog(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(`${baseUrl}/update_blog`, payload);
      return resolve(response.data);
    } catch (error) {}
  });
}
