import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Button, Form } from "reactstrap";
import { UncontrolledAlert } from "reactstrap";
import { getLogo, updateLogo } from "../../repositories/settingRepository";
import { useHistory } from "react-router-dom";
import { imageUrl } from "../../repositories/Repository";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import { set } from "lodash";

const Logo = (props) => {
  const [logo, setLogo] = useState("");
  const [logoId, setLogoId] = useState("");
  const [showLogo, setShowLogo] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const history = useHistory();

  useEffect(async () => {
    GetLogo();
  }, [props.success, refresh]);

  const GetLogo = async () => {
    console.log("fff", refresh);

    let res = await getLogo();
    setShowLogo(res.data.logo_url);
    setLogoId(res.data._id);
  };

  const logoOnchange = (e) => {
    console.log("hello file");
    console.log("files", e.target.files[0]);
    setLogo(e.target.files[0]);
  };

  async function handleValidSubmit(event, values) {
    console.log(values);
    let formD = new FormData();
    formD.append("logo_url", logo);
    formD.append("logo_id", logoId);
    let res = await updateLogo(formD);
    if (res.status == 1) {
      setSuccess(res.message);
      console.log(refresh, "before");

      setRefresh(!refresh);

      setTimeout(() => {
        setSuccess(null);
        console.log(refresh);
        // history.push("/dashboard");
        history.push("/logo");
      }, 3000);
    } else {
      setError(res.message);
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <Col lg="12">
            {error ? (
              <UncontrolledAlert color="danger" isOpen={true} dismissible>
                {error}
              </UncontrolledAlert>
            ) : null}
            {success ? (
              <UncontrolledAlert color="success" isOpen={true} dismissible>
                {success}
              </UncontrolledAlert>
            ) : null}
          </Col>
        </Row>

        <h4 className="card-title mb-4">Logo</h4>

        <Card>
          <CardBody>
            <Row>
              <Col lg="3" className="ms-auto me-auto">
                <img
                  src={`${imageUrl}${showLogo}`}
                  height={200}
                  width={200}
                  className="mb-3 "
                />
              </Col>
            </Row>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              <div className="form-group">
                <AvField
                  name="logo"
                  label="Update Logo"
                  onChange={logoOnchange}
                  key={refresh}
                  className="form-control"
                  placeholder="Enter Logo"
                  type="file"
                  required
                />
              </div>

              <div className="text-center mt-4">
                <Button type="submit" color="primary">
                  Update
                </Button>
              </div>
            </AvForm>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default Logo;
