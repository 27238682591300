import Repository, { baseUrl } from "./Repository";

export function addAttribute(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/add_attribute`,
        payload
      );

      return resolve(response.data);
    } catch (error) {}
  });
}
export function getAttribute(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/get_attribute`,
        payload
      );

      return resolve(response.data);
    } catch (error) {}
  });
}
export function updateAttribute(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/update_attribute`,
        payload
      );

      return resolve(response.data);
    } catch (error) {}
  });
}
export function addAttributeValue(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/add_attribute_value`,
        payload
      );

      return resolve(response.data);
    } catch (error) {}
  });
}
export function getAttributeValue(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/get_attribute_value`,
        payload
      );

      return resolve(response.data);
    } catch (error) {}
  });
}
export function updateAtributeValue(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/update_attribute_value`,
        payload
      );

      return resolve(response.data);
    } catch (error) {}
  });
}
export function addDescription(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/add_description`,
        payload
      );

      return resolve(response.data);
    } catch (error) {}
  });
}
export function getDescription(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/get_description`,
        payload
      );

      return resolve(response.data);
    } catch (error) {}
  });
}
export function updateDescription(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Repository.post(
        `${baseUrl}/update_description`,
        payload
      );

      return resolve(response.data);
    } catch (error) {}
  });
}
