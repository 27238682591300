import React from "react";
import {
  getGuestUser,
  deleteGuestUser,
} from "../../repositories/adminRepository";
import { useState, useEffect } from "react";
import { Table, Button, Row, Col, Card, CardBody } from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";

import ReactPaginate from "react-paginate";
import { UncontrolledAlert } from "reactstrap";
import { useHistory } from "react-router-dom";
// import { AiFillEdit } from "react-icons/ai";
// import { AiOutlineDelete } from "react-icons/ai";
import { Link } from "react-router-dom";

export default function LoanUserList() {
  const [userList, setUserList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [currentpage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const history = useHistory();

  let pagesize = 10;
  useEffect(() => {
    GetGuestUser();
    setRefresh(false);
  }, [currentpage, refresh]);

  const GetGuestUser = async () => {
    let res = await getGuestUser({
      page: currentpage,
      pagesize: pagesize,
      is_loan: true,
    });

    setUserList(res.data);
    setDataCount(res.count);
    let pageCount1 = Math.ceil(res.count / pagesize);
    setPageCount(pageCount1);
  };

  const onPageSubmit = (value) => {
    setCurrentPage(value.selected + 1);
    console.log("value", value.selected + 1);
  };
  const deleteUser = async (value) => {
    let res = await deleteGuestUser({
      guest_id: value,
    });
    if (res.status == 1) {
      setRefresh(true);
      setSuccess(res.message);
      setTimeout(() => {
        setSuccess(null);
      }, 5000);
    } else {
      setError(res.message);
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
    console.log(res);
  };
  return (
    <>
      <div className="page-content">
        <Row>
          <h4>Loan User List</h4>
        </Row>
        <Card>
          <CardBody>
            <br />

            <Row>
              <Col lg="12">
                {error ? (
                  <UncontrolledAlert color="danger" isOpen={true} dismissible>
                    {error}
                  </UncontrolledAlert>
                ) : null}
                {success ? (
                  <UncontrolledAlert color="success" isOpen={true} dismissible>
                    {success}
                  </UncontrolledAlert>
                ) : null}
              </Col>
            </Row>
            <div className="table-responsive">
              <Table hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Email</th>
                    <th>Name</th>
                    <th>Mobile</th>
                    <th>Amount</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {userList?.map((ele, index) => {
                    //   console.log("ele", ele)
                    return (
                      <tr key={ele._id}>
                        <th scope="row">{index + 1}</th>
                        <td>{ele.email}</td>
                        <td>{ele.name}</td>
                        <td>{ele.mobile}</td>
                        <td>{ele.amount}</td>

                        <div>
                          <Link>
                            <i
                              className=" fas fa-trash-alt"
                              size={20}
                              onClick={(e) => {
                                deleteUser(ele._id);
                              }}
                            />
                          </Link>
                        </div>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <div style={{ display: "flex" }}>
              <ReactPaginate
                breakLabel="..."
                nextLabel="next >"
                onPageChange={onPageSubmit}
                pageRangeDisplayed={5}
                pageCount={pageCount ? pageCount : 1}
                previousLabel="< previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}

                //   renderOnZeroPageCount={null}
              />
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
}
