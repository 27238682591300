import React, { Component } from "react";
import { Container, Row, Col, Label, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { loginAdmin } from "../../repositories/adminRepository";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Images
import logodark from "../../assets/images/logo-dark.png";
import avatar2 from "../../assets/images/users/avatar-2.jpg";

class AuthLockScreen extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }
  async handleSubmit(event, values) {
    let res = await loginAdmin({
      email: localStorage.getItem("username"),
      password: values.password,
    });
    console.log("login res", res);

    if (res.status === 1) {
      localStorage.setItem("authUser", res.access_token);
      localStorage.setItem("username", res.data.email);
      this.props.history.push("/dashboard");
    }
  }
  componentDidMount() {
    localStorage.removeItem("authUser");

    document.body.classList.add("auth-body-bg");
  }

  componentWillUnmount() {
    document.body.classList.remove("auth-body-bg");
  }

  const;

  render() {
    return (
      <React.Fragment>
        <div>
          <Container fluid className="p-0">
            <Row className="g-0">
              <Col lg={4}>
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100">
                    <Row className="justify-content-center">
                      <Col lg={9}>
                        <div>
                          <div className="text-center">
                            <div>
                              <Link to="/" className="logo">
                                <img src={logodark} height="20" alt="logo" />
                              </Link>
                            </div>

                            <h4 className="font-size-18 mt-4">Lock screen</h4>
                            <p className="text-muted">
                              Enter your password to unlock the screen!
                            </p>
                          </div>

                          <div className="p-2 mt-5">
                            <AvForm
                              className="form-horizontal"
                              onValidSubmit={this.handleSubmit}
                            >
                              <div className="user-thumb text-center mb-5">
                                <img
                                  src={avatar2}
                                  className="rounded-circle img-thumbnail avatar-md"
                                  alt="thumbnail"
                                />
                                <h5 className="font-size-15 mt-3">Admin</h5>
                              </div>

                              <div className="mb-3 auth-form-group-custom mb-4">
                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                <Label
                                  className="form-label"
                                  for="userpassword"
                                >
                                  Password
                                </Label>
                                <AvField
                                  name="password"
                                  validate={{ required: true }}
                                  type="password"
                                  className="form-control"
                                  id="userpassword"
                                  placeholder="Enter password"
                                />
                              </div>

                              <div className="mt-4 text-center">
                                <Button
                                  color="primary"
                                  className="w-md waves-effect waves-light"
                                  type="submit"
                                >
                                  Unblock
                                </Button>
                              </div>
                            </AvForm>
                          </div>

                          <div className="mt-5 text-center">
                            <p>
                              © 2022 Scholarship. Powered{" "}
                              <i className="mdi mdi-heart text-danger"></i> by
                              Revolution software services
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col lg={8}>
                <div className="authentication-bg">
                  <div className="bg-overlay"></div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(AuthLockScreen);
