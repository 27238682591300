import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

// Pages Calendar
import Calendar from "../pages/Calendar/Calendar";

// Pages Component
import Chat from "../pages/Chat/Chat";

// Location
import CountryList from "../pages/Location/CountryList";
import AddCountry from "../pages/Location/AddCountry";
import StateList from "../pages/Location/StateList";
import AddState from "../pages/Location/AddState";

// Setting
import AboutUs from "../pages/Settings/AboutUs";
import ContactUs from "../pages/Settings/ContactUs";
import FaqList from "../pages/Settings/FaqList";
import AddFaq from "../pages/Settings/AddFaq";
import PrivacyPolicy from "../pages/Settings/PrivacyPolicy";
import WorkingPolicy from "../pages/Settings/WorkingPolicy";
import TermAndCondition from "../pages/Settings/Term&Condition";
import Logo from "../pages/Settings/Logo";
import AddBlog from "../pages/Settings/AddBlog";
import BlogList from "../pages/Settings/BlogList";

// User
import UserList from "../pages/Users/UserList";
import UniversitiesList from "../pages/Users/UniversitiesList";
import ApprovalRequest from "../pages/Users/ApprovalRequest";
import AddLanguage from "../pages/Users/AddLanguage";
import LanguageList from "../pages/Users/LanguagesList";
import CategoryList from "../pages/Users/CourseCategoryList";
import AddCategory from "../pages/Users/AddCourseCategory";
import UserInsight from "../pages/Users/UserInsight";
import UniversityInsight from "../pages/Users/UniversityInsight";
import CourseList from "../pages/Users/CourseList";
import GuestUserList from "../pages/Users/GuestUserList";
import LoanUserList from "../pages/Users/LoanUserList";
import MoneyUserList from "../pages/Users/MoneyUserList";
import InternationalBankUser from "../pages/Users/InternationalBankUser";

// application
import ApplicationList from "../pages/Applications/ApplicationList";

//admin
import AdminProfile from "../pages/AuthenticationInner/AdminProfile";
import ChangePassword from "../pages/AuthenticationInner/ChangePassword";
// DropDown
import AddCourseLevel from "../pages/Dropdown/AddCourseLevel";
import CourseLevelList from "../pages/Dropdown/CourseLevelList";
import AddCourseType from "../pages/Dropdown/AddCourseType";
import CourseTypeList from "../pages/Dropdown/CourseTypeList";
import CourseDuration from "../pages/Dropdown/CourseDurationList";
import AddCourseDuration from "../pages/Dropdown/AddCourseDuration";
import AddCourseExperience from "../pages/Dropdown/AddCourseExperience";
import CourseExperience from "../pages/Dropdown/CourseExperienceList";

// attributes
import AttributeList from "../pages/Attributes/AttributeList";
import AddAttribute from "../pages/Attributes/AddAttribute";
import AddAttributeValue from "../pages/Attributes/AddAttributeValue";
import EditAttributeValue from "../pages/Attributes/EditAttributeValue";
import AddDescription from "../pages/Attributes/AddDescription";
import DescriptionList from "../pages/Attributes/DescriptionList";
//Ecommerce Pages
import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts/index";
import EcommerceProductDetail from "../pages/Ecommerce/EcommerceProducts/EcommerceProductDetail";
import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders/index";
import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers/index";
import EcommerceCart from "../pages/Ecommerce/EcommerceCart";
import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout";
import EcommerceShops from "../pages/Ecommerce/EcommerceShops/index";
import EcommerceAddProduct from "../pages/Ecommerce/EcommerceAddProduct";

//Email
import EmailInbox from "../pages/Email/email-inbox";
import EmailRead from "../pages/Email/email-read";

// Charts
import ChartApex from "../pages/Charts/Apexcharts";
import ChartjsChart from "../pages/Charts/ChartjsChart";
import SparklineChart from "../pages/Charts/SparklineChart";
import ChartsKnob from "../pages/Charts/jquery-knob";

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle";
import MapsVector from "../pages/Maps/MapsVector";

//Icons
import RemixIcons from "../pages/Icons/RemixIcons";
import MaterialDesign from "../pages/Icons/MaterialDesign";
import DripiIcons from "../pages/Icons/DripiIcons";
import FontAwesome from "../pages/Icons/FontAwesome";

//Utility
import StarterPage from "../pages/Utility/StarterPage";
import Maintenance from "../pages/Utility/Maintenance";
import CommingSoon from "../pages/Utility/CommingSoon";
import Timeline from "../pages/Utility/Timeline";
import FAQs from "../pages/Utility/FAQs";
import Pricing from "../pages/Utility/Pricing";
import Error404 from "../pages/Utility/Error404";
import Error500 from "../pages/Utility/Error500";

// Forms
import FormElements from "../pages/Forms/FormElements";
import FormAdvanced from "../pages/Forms/FormAdvanced";
import FormEditors from "../pages/Forms/FormEditors";
import FormValidations from "../pages/Forms/FormValidations";
import FormMask from "../pages/Forms/FormMask";
import FormUpload from "../pages/Forms/FormUpload";
import FormWizard from "../pages/Forms/FormWizard";
import FormXeditable from "../pages/Forms/FormXeditable";

//Ui
import UiAlert from "../pages/Ui/UiAlert";
import UiButtons from "../pages/Ui/UiButtons";
import UiCards from "../pages/Ui/UiCards";
import UiCarousel from "../pages/Ui/UiCarousel";
import UiDropdown from "../pages/Ui/UiDropdown";
import UiGeneral from "../pages/Ui/UiGeneral";
import UiGrid from "../pages/Ui/UiGrid";
import UiImages from "../pages/Ui/UiImages";
import UiLightbox from "../pages/Ui/UiLightbox";
import UiModal from "../pages/Ui/UiModal";
import UiProgressbar from "../pages/Ui/UiProgressbar";
import UiSweetAlert from "../pages/Ui/UiSweetAlert";
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
import UiTypography from "../pages/Ui/UiTypography";
import UiVideo from "../pages/Ui/UiVideo";
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
import UiRating from "../pages/Ui/UiRating";
import UiRangeSlider from "../pages/Ui/UiRangeSlider";
import UiNotifications from "../pages/Ui/ui-notifications";
import UIRoundSlider from "../pages/Ui/UIRoundSlider";

//Tables
import BasicTables from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";
import ResponsiveTables from "../pages/Tables/ResponsiveTables";
import EditableTables from "../pages/Tables/EditableTables";

// Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Register1 from "../pages/AuthenticationInner/Register";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";

//Kanban Board
import KanbanBoard from "../pages/KanbanBoard/index";
import userEvent from "@testing-library/user-event";

const authProtectedRoutes = [
  //Kanban Board
  { path: "/kanban-board", component: KanbanBoard },

  // Tables
  { path: "/basic-tables", component: BasicTables },
  { path: "/datatable-table", component: DatatableTables },
  { path: "/responsive-table", component: ResponsiveTables },
  { path: "/editable-table", component: EditableTables },

  // location
  { path: "/country-list", component: CountryList },
  { path: "/add-country/:id", component: AddCountry },
  // { path: "/state-list", component: StateList },
  // { path: "/add-state/:id", component: AddState },

  // settings
  { path: "/about-us", component: AboutUs },
  { path: "/contact-us", component: ContactUs },
  { path: "/privacy-policy", component: PrivacyPolicy },
  { path: "/working-policy", component: WorkingPolicy },
  { path: "/term-and-condition", component: TermAndCondition },
  { path: "/add-faq/:id", component: AddFaq },
  { path: "/faq-list", component: FaqList },
  { path: "/logo", component: Logo },
  { path: "/add-blog/:id", component: AddBlog },
  { path: "/blog", component: BlogList },

  // Admin
  { path: "/profile", component: AdminProfile },
  { path: "/change-password", component: ChangePassword },

  // user
  { path: "/student-list", component: UserList },
  { path: "/university-list", component: UniversitiesList },
  { path: "/approval-request", component: ApprovalRequest },
  { path: "/language-list", component: LanguageList },
  { path: "/add-language/:id", component: AddLanguage },
  { path: "/user-detail/:id", component: UserInsight },
  { path: "/university-detail/:id", component: UniversityInsight },
  { path: "/category-list", component: CategoryList },
  { path: "/add-category/:id", component: AddCategory },
  { path: "/view-courses/:id", component: CourseList },
  { path: "/guest-user", component: GuestUserList },
  { path: "/loan-user", component: LoanUserList },
  { path: "/money-user", component: MoneyUserList },
  { path: "/bank", component: InternationalBankUser },

  //  dropdown
  { path: "/course-level-list", component: CourseLevelList },
  { path: "/add-course-level/:id", component: AddCourseLevel },
  { path: "/course-type-list", component: CourseTypeList },
  { path: "/add-course-type/:id", component: AddCourseType },
  { path: "/course-duration-list", component: CourseDuration },
  { path: "/add-course-duration/:id", component: AddCourseDuration },

  { path: "/course-experience-list", component: CourseExperience },
  { path: "/add-course-experience/:id", component: AddCourseExperience },

  // Attribute
  { path: "/attribute-list", component: AttributeList },
  { path: "/add-attribute/:id", component: AddAttribute },
  { path: "/attribute-detail/:id", component: EditAttributeValue },
  { path: "/attribute-values/:id", component: AddAttributeValue },
  { path: "/add-description/:id", component: AddDescription },
  { path: "/description-list", component: DescriptionList },

  // Ui
  { path: "/ui-alerts", component: UiAlert },
  { path: "/ui-buttons", component: UiButtons },
  { path: "/ui-cards", component: UiCards },
  { path: "/ui-carousel", component: UiCarousel },
  { path: "/ui-dropdowns", component: UiDropdown },
  { path: "/ui-general", component: UiGeneral },
  { path: "/ui-grid", component: UiGrid },
  { path: "/ui-images", component: UiImages },
  { path: "/ui-lightbox", component: UiLightbox },
  { path: "/ui-modals", component: UiModal },
  { path: "/ui-progressbars", component: UiProgressbar },
  { path: "/ui-sweet-alert", component: UiSweetAlert },
  { path: "/ui-tabs-accordions", component: UiTabsAccordions },
  { path: "/ui-typography", component: UiTypography },
  { path: "/ui-video", component: UiVideo },
  { path: "/ui-session-timeout", component: UiSessionTimeout },
  { path: "/ui-rating", component: UiRating },
  { path: "/ui-rangeslider", component: UiRangeSlider },
  { path: "/ui-notifications", component: UiNotifications },
  { path: "/ui-roundslider", component: UIRoundSlider },

  // application
  { path: "/application-list", component: ApplicationList },

  // Forms
  { path: "/form-elements", component: FormElements },
  { path: "/form-advanced", component: FormAdvanced },
  { path: "/form-editors", component: FormEditors },
  { path: "/form-mask", component: FormMask },
  { path: "/form-file-upload", component: FormUpload },
  { path: "/form-wizard", component: FormWizard },
  { path: "/form-validation", component: FormValidations },
  { path: "/form-xeditable", component: FormXeditable },

  //Utility
  { path: "/starter", component: StarterPage },
  { path: "/timeline", component: Timeline },
  { path: "/faqs", component: FAQs },
  { path: "/pricing", component: Pricing },

  //Icons
  { path: "/icons-remix", component: RemixIcons },
  { path: "/material-design", component: MaterialDesign },
  { path: "/dripicons", component: DripiIcons },
  { path: "/font-awesome-5", component: FontAwesome },

  // Maps
  { path: "/google-maps", component: MapsGoogle },
  { path: "/vector-maps", component: MapsVector },

  //Charts
  { path: "/apex-charts", component: ChartApex },
  { path: "/chartjs", component: ChartjsChart },
  { path: "/charts-sparkline", component: SparklineChart },
  { path: "/charts-knob", component: ChartsKnob },

  //Email
  { path: "/email-inbox", component: EmailInbox },
  { path: "/email-read", component: EmailRead },

  //Ecommerce

  { path: "/ecommerce-products", component: EcommerceProducts },
  { path: "/ecommerce-product-detail/:id", component: EcommerceProductDetail },
  { path: "/ecommerce-orders", component: EcommerceOrders },
  { path: "/ecommerce-customers", component: EcommerceCustomers },
  { path: "/ecommerce-cart", component: EcommerceCart },
  { path: "/ecommerce-checkout", component: EcommerceCheckout },
  { path: "/ecommerce-shops", component: EcommerceShops },
  { path: "/ecommerce-add-product", component: EcommerceAddProduct },

  //chat
  { path: "/chat", component: Chat },

  //calendar
  { path: "/calendar", component: Calendar },

  { path: "/dashboard", component: Dashboard },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  // { path: "/forgot-password", component: ForgetPwd },
  // { path: "/register", component: Register },
  { path: "/lock-screen", component: AuthLockScreen },

  // Authentication Inner
  { path: "/auth-login", component: Login1 },
  { path: "/auth-register", component: Register1 },
  { path: "/auth-recoverpw", component: ForgetPwd1 },

  { path: "/maintenance", component: Maintenance },
  { path: "/comingsoon", component: CommingSoon },
  { path: "/404", component: Error404 },
  { path: "/500", component: Error500 },
];

export { authProtectedRoutes, publicRoutes };
